.deal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2vh 5vw 0vh 5vw;
    background-color: aliceblue;
    padding: 1.5vh 3vw 1.5vh 3vw;
    border-radius: 2px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.deal-list-grid {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1vw;
    width: 100%;
    flex-wrap: wrap;
}  

.deal-list {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
}

