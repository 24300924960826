.main-image {
    object-fit: scale-down;
    border-radius: 15px;
    background-color: aliceblue;
    padding: 1vw;
}

.product-contents {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 20px;
}

.product-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2vw;
}

.detail-header {
    color: white;
    text-align: center;
    font-size: 4pt;
}