.portal-header {
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-top: 1vh;
    margin-top: 10px;
    margin-bottom: 20px;

    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    background-color: rgb(252, 252, 252);
}

.img-with-text {
    margin-left: 50px;
    margin-right: 50px;
}

@media (max-width: 700px) {
    .img-with-text {
        margin-left: 1px;
        margin-right: 1px;
    }
}