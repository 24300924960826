@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

body {
  background: rgb(1,108,177);
  background: linear-gradient(90deg, rgba(1,108,177,1) 0%, rgba(0,126,207,1) 35%, rgba(0,212,255,1) 100%);
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: rgb(1,108,177,0.5);
}

body::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 20px;
  border: rgb(40, 44, 52, 0.5) solid 3px;
}

div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  background: rgb(1,108,177,0.5);
  border-radius: 20px;
}

div::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 20px;
  border: rgb(40, 44, 52, 0.5) solid 1.5px;
}

.App {
  text-align: center;
  height: 100vh;
}

.logout {
  color: white !important;
  margin-left: 10px;
}

.logo {
  color: red;
  font-weight: bold;
  letter-spacing: 10px;
  font-size: 50px;
}

.user-box {
  background: #7f7fff;
  padding: 15px;
  border-radius: 26px;
  color: white;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}
.app_icon {
  width: 70px;
  height: 70px;
}
.redep {
  display: block;
}
.block {
  width: 100%;
  background: white;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.apps-title {
  letter-spacing: 1px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 6px;
  text-align: center;
}

.app_container {
  transition: 1s ease-in-out;
}

.app_title {
  font-weight: bold;
  padding-top: 5px;
  text-align: center;
  display: inline-block;
}

.app_container:hover {
  transition: 1s ease-in-out;
  background: #efefef;
  cursor: pointer;
}

.control-logo {
  font-weight: bold;
  color: white;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 2px;
}

.login_btn {
  background: black;
  color: white;
}

.login-container {
  padding-top: 10%;
}

.MuiInputLabel-filled {
  color: white !important;
}

#okta-sign-in.auth-container.main-container {
  background: none !important;
  border: 0 !important;
}

.animated-linear {
  background: linear-gradient(226deg, #006bff, #0f57bb, #5192ec, #4551ff);
  background-size: 800% 800%;

  -webkit-animation: AnimationName 16s ease infinite;
  -moz-animation: AnimationName 16s ease infinite;
  animation: AnimationName 16s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.control-header {
}

.infobox-error {
  color: black !important;
}

#okta-sign-in .auth-content {
  padding: 0 !important;
}

.okta-form-title {
  color: white !important;
}

.original-doc {
  position: absolute;
  transform: rotateZ(328deg);
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 27px;
  margin-left: 41px;
  background: white;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}
.original-doc2 {
  position: absolute;
  transform: rotateZ(328deg);
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 105px;
  margin-left: 162px;
  font-size: 61px;
  background: white;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

.sign-title {
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.border-grey {
  border: 2px solid #e6e6e6;
}

#okta-sign-in {
  margin-top: 0px !important;
  color: white !important;
  width: 100% !important;
  margin: 0 !important;
}

#okta-sign-in.no-beacon .auth-header {
  padding: 0 !important;
}

.okta-form-input-error {
  color: white !important;
}

.auth-footer a {
  color: white !important;
}

.okta-form-label {
  color: white !important;
  text-align: left;
}

.okta-sign-in-header {
  border: 0 !important;
}

.overlay {
  height: 100vh;
  opacity: 0.9;
  padding-top: 10%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.img-with-text {
  text-align: center;
  width: 100px;
  padding-top: 5px;
}
.img-with-text img {
  display: block;
  margin: 0 auto;
  text-align: center;
}
