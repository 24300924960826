.contract-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    border-radius: 2px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    margin: 2rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
    text-align: center;
    width: 20vw;
}

.contract-box-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2vw;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 2vw;
    box-shadow: rgb(50 50 93 / 25%) 0px 0px 0px 0px, rgb(0 0 0 / 30%) 0px 5px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
    margin-bottom: 2vh;
    width: 100%;
}

.contract-box-list > * {
    display: inline;
    text-align: center;
}

.contract-image {
    box-shadow: 0 0 2px 2px aliceblue inset;
    border-radius: 360px;
    object-fit: scale-down;
    background-color: rgb(192 234 251 / 90%);
    filter: grayscale(0.5);
    padding: 10px;
    border: none;
}